<template>
    <div>

        <section class="section">
            <div class="container has-text-centered">
                <h1 class="title is-3">{{ $t('faq_title') }}</h1>
            </div>
        </section>

        <section class="section">
            <div class="container">
                <div class="content">
                    <ul class="list-rectangle">
                        <li v-for="i in 22" :key="i">
                            <h5 v-html="i + '. ' + $t('faqq' + i)"></h5>
                            <p v-html="$t('faqa' + i)"></p>
                            <img v-if="i === 11" :src="require(`@/assets/img/paymentorder.png`)" class="img-bank">
                        </li>
                    </ul>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
  name: 'FAQ',
  computed: {
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped>
  .img-bank {
    width: 600px;
  }
   h5 {
       margin: 0;
   }
   b {
       color: #565656;
   }
   .list-rectangle {
       list-style: none;
       margin: 0;
       padding: 0;
   }
   .list-rectangle>li {
       position: relative;
       display: block;
       margin-bottom: .25rem;
       padding: .325rem .825rem .325rem 1.325rem;
       color: rgba(0, 0, 0, 0.98);
       background: #f9fbff;
   }
   .list-rectangle>li:last-child {
       margin-bottom: 0;
   }
   .list-rectangle>li::before {
       content: "";
       position: absolute;
       left: 0;
       top: 0;
       bottom: 0;
       width: 0.5rem;
       background: #e6ebf5;
   }
</style>
